import axios from "axios";
import Head from "next/head";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Header from "/sections/header/index.jsx";
import Footer from "/sections/footer/index.jsx";
import Cookies from "js-cookie";

let exitIntentListenerAdded = false;

export default function GenericLayout({
  title,
  mainClassName = "",
  description,
  schema = null,
  shareImageUrl = null,
  color = "purple",
  children,
  canonical = null,
  customMetaTags = [],
}) {
  const router = useRouter();
  const [popupShown, setPopupShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add a state to track submission status

  const excludedPaths = ["/thank-you", "customer-portal", "/agency-overview-presentation"]; //add more paths as needed

  useEffect(() => {
    const exitPopup = document.getElementById("exitPopup");

    const showExitPopup = () => {
      if (!popupShown) {
        exitPopup.style.display = "flex";
        Cookies.set("exitPopupShown", "true", { expires: 2 }); //set cookie to expire in 2 days
        setPopupShown(true);
      }
    };

    const closeExitPopup = () => {
      exitPopup.style.display = "none";
      setPopupShown(true);
    };

    const handleMouseOut = (event) => {
      if (event.clientY <= 0) {
        showExitPopup();
      }
    };

    if (!excludedPaths.includes(router.pathname)) {
      if (!Cookies.get("exitPopupShown") && !popupShown && !exitIntentListenerAdded) {
        document.addEventListener("mouseout", handleMouseOut);
        exitIntentListenerAdded = true;

        if (/Mobi|Android/i.test(navigator.userAgent)) {
          setTimeout(() => {
            showExitPopup();
          }, 10000);
        }
      }

      return () => {
        document.removeEventListener("mouseout", handleMouseOut);
        exitIntentListenerAdded = false;
      };
    }
  }, [popupShown, router.pathname]);

  const submitForm = async() => {
    //prevent double submission
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;

    if (!firstName || !lastName || !email) {
      setIsSubmitting(false);
      return;
    }

    const data = {
      firstName,
      lastName,
      email,
    };

    //send GTM dataLayer event if possible
    try {
      window.dataLayer.push({ "event": "popupFormSubmitted" });
    } catch (err) {}

    try {
      await axios.post("/api/sendToZapier", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      //after successful form submission, redirect the user
      window.location.href = "https://deviatelabs.com/thank-you";
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      setIsSubmitting(false); //ensure the submission state is reset
    }
  };


  return (
    <div id="root" style={{
      "--color": `var(--${color})`,
      "--colorHue": `var(--${color}Hue)`,
      "--colorHsl": `var(--${color}Hsl)`,
    }} >
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
        {shareImageUrl && <meta property="og:image" content={shareImageUrl} />}
        {canonical && <link rel="canonical" href={process.env.NEXT_PUBLIC_SITE_URL + canonical} />}
        {/*render custom meta tags here */}
        {customMetaTags.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
      </Head>
      <Header />
      <main className={mainClassName}>{children}</main>
      <div style={{ display: "none" }} className="exit-popup" id="exitPopup" onClick={(event) => {
        if (event.target === event.currentTarget) {
          document.getElementById("exitPopup").style.display = "none";
        }
      }}>
        <div className="popup-content" id="popupContent" data-dashlane-rid="aa93a29d39dae564" data-form-type="register">
          <div className="popup-title">Get Our Agency Overview</div>
          <div className="popup-subtitle">
            Featuring details about our agency, clients we&apos;ve worked with, specific services and case studies, this agency overview will give you insight on how we deliver tangible results for our clients.
          </div>
          <div className="popup-input-row">
            <input type="text" placeholder="First Name" id="firstName" data-form-type="name,first" />
            <input type="text" placeholder="Last Name" id="lastName" data-form-type="name,last" />
            <input type="email" placeholder="Email Address" id="email" data-form-type="email" />
          </div>
          <button onClick={submitForm} data-form-type="action" style={{ "--color": "var(--yellow)", margin: "0 auto" }} className="button_deviateButton__oqnys button_light__GrJcY button_ghost__pDSJT">
            <span style={{ color: "#ffce00" }}>Download Now</span>
            <svg viewBox="0 0 230 61" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <g fill="none" fillRule="evenodd">
                <path vectorEffect="non-scaling-stroke" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" d="M40 2h188v15M228 32v27h-50M168 59h-32M126 59h-4.69m-8.56 0H2V2h28.086"></path>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <Footer />
      <style jsx>{`
        .exit-popup {
          display: none; /* Initially hidden */
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgb(255 255 255 / 0%);
          backdrop-filter: blur(50px) brightness(70%);
          -webkit-backdrop-filter: blur(50px) brightness(70%);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        #exitPopup::after{
          content:  "X";
          font-weight: bold;
          position: absolute;
          top: 98%;
          transform: translateY(-50%);
          padding: 5px;
          cursor: pointer;
          font-size: 16px;
          color: white;
        }
        .popup-content {
          padding: 40px;
          border-radius: 10px;
          text-align: center;
          max-width: 850px; /* Only one max-width definition */
          margin: 0 auto;
          position: relative;
        }
        .popup-title {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .popup-subtitle {
          font-size: 18px;
          margin-bottom: 30px;
          line-height: 1.5;
        }
        .popup-input-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }
        .popup-input-row input {
          padding: 10px;
          width: 30%;
          box-sizing: border-box;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 5px;
          color: #fff;
          font-size: 16px;
        }
        .popup-input-row input::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
        .popup-button {
          background-color: #FFAA00;
          color: #1a1a1a;
          border: none;
          padding: 15px 30px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .popup-button:hover {
          background-color: #E69900;
        }
        .popup-button svg {
          margin-left: 10px;
        }
      `}</style>
    </div>
  );
}
